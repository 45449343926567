import React from 'react';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const Product = ({ product, addToCart }) => {
  console.log(product.photo)

  return (

    <div className="row">
      <div className="col col-lg-5 col-12">
        <div className="shop-single-slider">
          <div className="slider-nav">
            <div>
              <Zoom>
                <img src={product.photo ? product.photo : ''} alt="products" />
              </Zoom>
            </div>
          </div>
        </div>
      </div>
      <div className="col col-lg-7 col-12">
        <div className="product-details">
          <h2>{product.nom}</h2>
          <div className="product-rt">
            <div className="rating">
              <i className="flaticon-star"></i>
              <i className="flaticon-star"></i>
              <i className="flaticon-star"></i>
              <i className="flaticon-star"></i>
              <i className="flaticon-star-1"></i>
            </div>
          </div>
          <div className="price">
            <span className="current">{product.prix} Xaf</span>
            {/* <span className="old">{product.prix} Xaf</span> */}
          </div>
          <p>{product.description}</p>
          <div className="product-option">
            <div className="product-row">
              <button className="theme-btn"
                onClick={() => addToCart(product)}>Ajouter au panier</button>
              <div>
              </div>
            </div>
          </div>
          <div className="tg-btm">
            <p><span>Categories:</span>{product.category}</p>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Product;
