import React from 'react';
import { Link } from "react-router-dom";
import SectionTitle from '../SectionTitle/SectionTitle';


const SalePoint = () => {
    return (
        <div className='wpo-shop-section'>
            <style>
                {`
                .card-container {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin: 20px;
                }

                .card {
                    padding: 10px;
                    text-align: center;
                    width: 400px;
                    margin-top: 5px;
                }

                .card img {
                    max-width: 100%;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .card h2 {
                    font-size: 1.5em;
                    margin: 10px 0;
                }

                .card a {
                    text-decoration: none;
                    color: #333;
                }

                .card p {
                    font-size: 1em;
                    color: #000;
                }
                `}
            </style>
            <div className='container'>
                <div className='row'>
                    <div className="col-12">
                        <SectionTitle subtitle={'Points de vente'} title={'Nos différents points de vente'} />
                    </div>
                    <div className="card-container row">
                        <div className="card">
                            <Link to="/menu-akwa"><img src="/product/Repas/20.jpeg" alt="Image 1" /></Link>
                            <h2 style={{fontWeight:"bold"}}>Akwa</h2>
                            {/* <p>Ceci est un texte descriptif pour la première carte.</p> */}
                        </div>
                        <div className="card">
                            <Link to="/menu-bonamoussadi"><img src="/product/Repas/1.jpeg" alt="Image 2" /></Link>
                            <h2 style={{fontWeight:"bold"}}>Bonamoussadi</h2>
                            {/* <p>Ceci est un texte descriptif pour la deuxième carte.</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SalePoint;