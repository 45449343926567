
/* Single image */
import Fimg1 from '../images/icon/1.png'
import Fimg2 from '../images/icon/2.png'
import Fimg3 from '../images/icon/3.png'



const Features = [
    {
        id: 1,
        Fimage: Fimg1,
        title: 'Livraison Rapide',
        description: 'Recevez vos achats où que vous soyez.',
    },
    {
        id: 2,
        Fimage: Fimg2,
        title: 'Support 24/07',
        description: 'N\'hésitez pas à nous contacter pour toute question.',
    },
    {
        id: 3,
        Fimage: Fimg3,
        title: 'Paiement Sécurisé',
        description: 'Paiement sécurisé garanti. Vos informations sont protégées.',
    }
    

];
export default Features;