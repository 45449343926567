import React from 'react';

const Astuce = () => {
    return (
        <section className='wpo-blog-pg-section section-padding'>
            <div className="container">
                <div className='row'>
                    <h1 style={{textAlign:"center"}}> Bientôt disponible !</h1>
                </div>
            </div>
        </section>
    );
}

export default Astuce;