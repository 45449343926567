import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import api from "../../api";
import { addToCart } from "../../store/actions/action";
import PopupQuickview from '../PopupQuickview/PopupQuickview';
import axios from 'axios';

const ShopPageSection = ({ addToCart }) => {
    const [shopeTab, setShopeTab] = useState(0);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductClick = (product) => {
        setSelectedProduct(product);
    };

    const handleCloseClick = () => {
        setSelectedProduct(null);
    };


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://www.api.dfit.fr/api/products/list', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                setProducts(response.data.products);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchProducts();
    }, []);

    const addToCartProduct = (product, qty = 1) => {
        addToCart(product, qty);
    };
    return (
        <div className="shop-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="shop-section-top-inner">
                            <div className="shoping-list">
                                <ul className="nav main-tab"  >
                                    <li className="nav-item">
                                        <button
                                            className={`nav-link ${shopeTab === 0 ? 'active' : ''}`}
                                            onClick={() => setShopeTab(0)}
                                        >
                                            <i className="fa fa-th"></i>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button
                                            className={`nav-link ${shopeTab === 1 ? 'active' : ''}`}
                                            onClick={() => setShopeTab(1)}
                                        >
                                            <i className="fa fa-list"></i>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="tab-content">
                            {shopeTab === 1 &&
                                <div className="row">
                                    {products?.length > 0 &&
                                        products.map((product, pitem) => (
                                            <div className="col-lg-3 col-md-6 col-12" key={pitem}>
                                                <div className="orico-product-single">
                                                    <div className="orico-product-item">
                                                        <div className="orico-product-img">
                                                            <img src={product.photo} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="orico-product-text">
                                                        <h2><Link onClick={ClickHandler} to={`/shop-single/${product.slug}`}>{product.nom}</Link></h2>
                                                        <div className="product-price">
                                                            <ul>
                                                                <li>{}</li>
                                                                <li>{product.prix} Xaf</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="orico-product-text-hide">
                                                        <ul className="orico-product-link">
                                                            {/* <li><a href="#"><i className="fi ti-heart"></i></a></li> */}
                                                            <li><button onClick={() => addToCartProduct(product)}>
                                                                <i className="fi flaticon-shopping-cart"
                                                                    aria-hidden="true"></i></button>
                                                            </li>
                                                            <li>
                                                                <button onClick={() => handleProductClick(product)}><i className="fi ti-eye"></i></button>
                                                            </li>
                                                        </ul>
                                                        <h2><Link onClick={ClickHandler} to={`/shop-single/${product.slug}`}>{product.nom}</Link></h2>
                                                        <div className="product-price">
                                                            <ul>
                                                                <li>{}</li>
                                                                <li>{product.prix} Xaf</li>
                                                            </ul>
                                                        </div>
                                                        <a style={{cursor:'pointer'}}
                                                            data-bs-toggle="tooltip"
                                                            data-bs-html="true"
                                                            title="Add to Cart"
                                                            onClick={() => addToCartProduct(product)} className="cart-btn">Ajouter au apnier</a>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    
                                </div>
                            }
                            {shopeTab === 0 &&
                                <div className="row product-list">
                                    {products?.length > 0 &&
                                        products.map((product, pitem) => (
                                            <div className="col-12" key={pitem}>
                                                <div className="orico-product-single">
                                                    <div className="orico-product-item">
                                                        <div className="orico-product-img">
                                                            <img src={product.photo} alt="" />
                                                        </div>
                                                        <div className="orico-product-text-hide">
                                                            <ul className="orico-product-link">
                                                                <li><button onClick={() => addToCartProduct(product)}>
                                                                    <i className="fi flaticon-shopping-cart"
                                                                        aria-hidden="true"></i></button>
                                                                </li>
                                                                <li>
                                                                    <button onClick={() => handleProductClick(product)}><i className="fi ti-eye"></i></button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="orico-product-text">
                                                        <h2><Link onClick={ClickHandler} to={`/shop-single/${product.slug}`}>{product.nom}</Link></h2>
                                                        <div className="product-price">
                                                            <ul>
                                                                <li>{}</li>
                                                                <li>{product.prix} Xaf</li>
                                                            </ul>
                                                        </div>
                                                        <p>{product.description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PopupQuickview product={selectedProduct} handleCloseClick={handleCloseClick} />
        </div>

    );
};

export default connect(null, { addToCart })(ShopPageSection);