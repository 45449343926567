/* Single image */
import Timg1 from '../images/team/Ingrid-Ngoune.jpg'
import Timg2 from '../images/team/patricia.jpg'
import Timg3 from '../images/team/img-3.jpg'
import Timg4 from '../images/team/img-4.jpg'
import Timg5 from '../images/team/jude.jpg'





const Services = [
    {
        id: 1,
        simage: Timg1,
        title: 'Ingrid Ngoune',
        Subtitle: 'CEO & Fondatrice',
    },
 
    {
        id: 3,
        simage: Timg3,
        title: 'Roger Kouembou',
        Subtitle: 'Manager',
    },
 
    {
        id: 4,
        simage: Timg4,
        title: 'Okwen Margart',
        Subtitle: 'Chef Cuisinière',
    },

    {
        id: 2,
        simage: Timg2,
        title: ' Patricia Tchamou',
        Subtitle: 'Infirmière',
    },

    {
        id: 5,
        simage: Timg5,
        title: 'Ebah  Jude ',
        Subtitle: 'Responsable Ventes',
    },
 



];
export default Services;