import React, { Fragment } from 'react';
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import GallerySection from '../../components/GallerySection/Gallery';
import Logo from '../../images/logo-dfit.png'


const GalleryPage = () => {
    return (
        <Fragment>
            <HeaderTop />
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <PageTitle pageTitle={'Notre galerie'} pagesub={'Galerie'} />
            <GallerySection />
            <Footer FooterShape={false} />
            <Scrollbar />
        </Fragment>
    )
};
export default GalleryPage;