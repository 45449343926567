import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";


import './style.scss';
import axios from 'axios';

const SignUpPage = (props) => {

    const push = useNavigate()

    const [value, setValue] = useState({
        email: '',
        nom: '',
        telephone:'',
        password: '',
    });

    const changeHandler = (e) => {
        setValue({ ...value, [e.target.name]: e.target.value });
        validator.showMessages();
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));


    const submitForm = async (e) => {
        e.preventDefault();
        if (validator.allValid()) {
          try {
            await axios.post('https://www.api.dfit.fr/api/register', value,
            {
                headers: {'Content-Type' : 'application/json', 'Accept' : 'application/json'},
            }).then((res) => console.log(res))
            .catch((err) => console.log(err.data.message));
            setValue({
                email: '',
                nom: '',
                telephone:'',
                password: '',
            });
            localStorage.setItem('user', JSON.stringify({
                email: value.email,
                nom: value.nom,
                telephone: value.telephone,
            }));
            toast.success('Inscription réussie !');
            // if (response.status === 201) { 
            //   setValue({
            //     email: '',
            //     full_name: '',
            //     phone: '',
            //     password: '',
            //     confirm_password: '',
            //   });
            //   validator.hideMessages();
            //   toast.success('Inscription réussie !');
            //   push('/login');
            // } else {
            //   toast.error('Une erreur s\'est produite lors de l\'inscription.');
            // }
          } catch (error) {
            console.error('Type d\'erreur :', error.message);
            toast.error('Une erreur inattendue s\'est produite.');
          }
        } else {
          validator.showMessages();
          toast.error('Veuillez remplir tous les champs.');
        }
      };
    return (
        <Grid className="loginWrapper">

            <Grid className="loginForm">
                <h2>S'inscrire</h2>
                <p>Créer votre compte</p>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Full Name"
                                value={value.nom}
                                variant="outlined"
                                name="nom"
                                label="Name"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('full name', value.nom, 'required|alpha_space')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="E-mail"
                                value={value.email}
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('email', value.email, 'required|email')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                               className="inputOutline"
                               fullWidth
                               placeholder='Your phone'
                               value={value.telephone}
                               variant="outlined"
                               name="telephone"
                               label="Your phone"
                               InputLabelProps={{
                                    shrink: true,
                               }}     
                               onBlur={(e) => changeHandler(e)}
                               onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('telephone', value.telephone, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Password"
                                value={value.password}
                                variant="outlined"
                                name="password"
                                label="Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('password', value.password, 'required')}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                className="inputOutline"
                                fullWidth
                                placeholder="Confirm Password"
                                value={value.password}
                                variant="outlined"
                                name="confirm_password"
                                label="Confirm Password"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onBlur={(e) => changeHandler(e)}
                                onChange={(e) => changeHandler(e)}
                            />
                            {validator.message('confirm password', value.confirm_password, `in:${value.password}`)}
                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <Button fullWidth className="cBtn cBtnLarge cBtnTheme" type="submit">S'inscrire</Button>
                            </Grid>
                            {/* <Grid className="loginWithSocial">
                                <Button className="facebook"><i className="fa fa-facebook"></i></Button>
                                <Button className="twitter"><i className="fa fa-twitter"></i></Button>
                                <Button className="linkedin"><i className="fa fa-linkedin"></i></Button>
                            </Grid> */}
                            <p className="noteHelp">Vous avez déjà un compte? <Link to="/login">Connexion</Link>
                            </p>
                        </Grid>
                    </Grid>
                </form>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
    )
};

export default SignUpPage;