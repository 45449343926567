import React, {Fragment, useEffect, useState} from 'react';
import { useParams } from 'react-router-dom'
import { connect } from "react-redux";
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { addToCart } from "../../store/actions/action";
import Product from './product'
import axios from 'axios';
import Footer from '../../components/footer/Footer';
import Logo from '../../images/logo.svg'


const ProductSinglePage =(props) => {
    const { slug } = useParams()
    const {addToCart} = props;
    const [product, setProduct] = useState({});
    
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`https://www.api.dfit.fr/api/products/list`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });
                const foundProduct = response.data.products.find(product => product.slug === slug);
                setProduct(foundProduct);
            } catch (error) {
                console.error('Error fetching product:', error);
            }
        };
        
        fetchProduct();
    }, [slug]);
    

    return(
        <Fragment>
            <HeaderTop />
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <PageTitle pageTitle={'Shop Single'} pagesub={'Shop Single'}/> 
            <section className="shop_single section-padding">
                <div className="container">
                    {product ? <Product
                        product={product}
                        addToCart={addToCart}
                    /> : null}
                    {/* <ProductTabs/> */}
                </div>
            </section>
            <Footer FooterShape={false} />
            <Scrollbar/>
        </Fragment>
    )
};

const mapStateToProps = state => {
    return {
        products: state.data.products,
    }
};

export default connect(mapStateToProps, { addToCart })(ProductSinglePage);
