import React from 'react';
import { Link } from 'react-router-dom';


// image
import Ab from '../../images/about/cabinet.jpg'
// import Ab from '../../images/about/ab.jpg'
import Abd1 from '../../images/about/1.png'
import Abd2 from '../../images/about/4.png'
import Abd3 from '../../images/about/5.png'
import Abd4 from '../../images/about/3.png'
import sine from '../../images/about/2.png'

const book = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <section className={"" + props.hclass}>
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-4 col-md-12 col-12">
                        <div className="orico-about-wrap">
                            <div className="orico-about-img">
                                <div className="mask">
                                    <img src={Ab} alt=""/>
                                </div>
                                <div className="orico-ab-logo">
                                    <img src={Abd1} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-12">
                        <div className="orico-about-text-wrap">
                            <div className="orico-about-text">
                                <span>“<span>Consultation diététique</span>”</span>
                                {/* <h2>Consultation Diététique</h2> */}
                                <p>Nos diététiciens qualifiés vous aident à adopter une alimentation équilibrée et variée. Ensemble, nous définirons vos objectifs (perte de poids, prise de masse, amélioration de la santé...) et mettrons en place un programme nutritionnel sur mesure. Nous vous apporterons les outils et les connaissances nécessaires pour une transformation durable de vos habitudes alimentaires.</p>
                                <p>Souffrez-vous de troubles digestifs, de problèmes de poids ou souhaitez-vous simplement améliorer votre forme ? Nos consultations diététiques vous offrent un accompagnement personnalisé selon vos besois.</p>
                                <ul>
                                    <li><i><img src={Abd2} alt=""/></i> Prévenir les maladies chroniques</li>
                                    <li><i><img src={Abd3} alt=""/></i> Améliorer votre bien-être général</li>
                                    <li><i><img src={Abd2} alt=""/></i> Mieux gérer les intolérances alimentaires</li>
                                </ul>
                                <Link onClick={ClickHandler} className="theme-btn-s2" to="/contact">Prendre Contact</Link>
                            </div>
                            <div className="ab-onion-shape"><img src={Abd4} alt=""/></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ab-shape"><img src={sine} alt=""/></div>
        </section>
    );
};

export default book;