import React, { useState } from 'react';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { shuffleArray } from '../../utils';
import { useEffect } from 'react';

const Portfolios = [
    { Pimg: '/product/produit/delight-orange.jpeg' },
    { Pimg: '/product/produit/delight.jpeg' },
    { Pimg: '/product/produit/digeste-verre.jpeg' },
    { Pimg: '/product/produit/digeste.jpeg' },
    { Pimg: '/product/produit/energisante.jpeg' },
    { Pimg: '/product/produit/ginger.jpeg' },
    { Pimg: '/product/produit/green-detox.jpeg' },
    { Pimg: '/product/produit/Hibiscus-verre.jpeg' },
    { Pimg: '/product/produit/hibiscus.jpeg' },
    { Pimg: '/product/produit/limonade.jpeg' },
    { Pimg: '/product/produit/relaxante.jpeg' },
    { Pimg: '/product/produit/therapeutique.jpeg' },
    { Pimg: '/product/produit/tonifiante.jpeg' },
    { Pimg: '/product/produit/all-saveur-1.jpeg' },
    { Pimg: '/product/produit/all-saveur-3.jpeg' },
    { Pimg: '/product/produit/all-saveur-4.jpeg' },
    { Pimg: '/product/produit/all-vue-haut.jpeg' },
    { Pimg: '/product/Repas/1.jpeg' },
    { Pimg: '/product/Repas/2.jpeg' },
    { Pimg: '/product/Repas/3.jpeg' },
    { Pimg: '/product/Repas/4.jpeg' },
    { Pimg: '/product/Repas/5.jpeg' },
    { Pimg: '/product/Repas/6.jpeg' },
    { Pimg: '/product/Repas/7.jpeg' },
    { Pimg: '/product/Repas/8.jpeg' },
    { Pimg: '/product/Repas/9.jpeg' },
    { Pimg: '/product/Repas/11.jpeg' },
    { Pimg: '/product/Repas/12.jpeg' },
    { Pimg: '/product/Repas/13.jpeg' },
    { Pimg: '/product/Repas/14.jpeg' },
    { Pimg: '/product/Repas/15.jpeg' },
    { Pimg: '/product/Repas/16.jpeg' },
    { Pimg: '/product/Repas/17.jpeg' },
    { Pimg: '/product/Repas/18.jpeg' },
    { Pimg: '/product/Repas/19.jpeg' },
    { Pimg: '/product/Repas/20.jpeg' },
    { Pimg: '/product/Repas/21.jpeg' },
];

const PortfoliosTwo = [
    { Pimg: '/product/5-ans-DFit/1.jpeg' },
    { Pimg: '/product/5-ans-DFit/2.jpeg' },
    { Pimg: '/product/5-ans-DFit/3.jpeg' },
    { Pimg: '/product/5-ans-DFit/4.jpeg' },
    { Pimg: '/product/5-ans-DFit/5.jpeg' },
    { Pimg: '/product/5-ans-DFit/6.jpeg' },
    { Pimg: '/product/5-ans-DFit/7.jpeg' },
    { Pimg: '/product/5-ans-DFit/8.jpeg' },
    { Pimg: '/product/5-ans-DFit/9.jpeg' },
    { Pimg: '/product/5-ans-DFit/10.jpeg' },
    { Pimg: '/product/5-ans-DFit/11.jpeg' },
    { Pimg: '/product/5-ans-DFit/12.jpeg' },
    { Pimg: '/product/5-ans-DFit/13.jpeg' },
    { Pimg: '/product/5-ans-DFit/14.jpeg' },
    { Pimg: '/product/5-ans-DFit/15.jpeg' },
    { Pimg: '/product/5-ans-DFit/16.jpeg' },
    { Pimg: '/product/5-ans-DFit/17.jpeg' },
    { Pimg: '/product/5-ans-DFit/18.jpeg' },
    { Pimg: '/product/5-ans-DFit/19.jpeg' },
    { Pimg: '/product/5-ans-DFit/20.jpeg' },
    { Pimg: '/product/5-ans-DFit/21.jpeg' },
    { Pimg: '/product/5-ans-DFit/22.jpeg' },
    { Pimg: '/product/5-ans-DFit/23.jpeg' },
    { Pimg: '/product/5-ans-DFit/24.jpeg' },
    { Pimg: '/product/5-ans-DFit/25.jpeg' },
    { Pimg: '/product/5-ans-DFit/26.jpeg' },
    { Pimg: '/product/5-ans-DFit/27.jpeg' },
    { Pimg: '/product/5-ans-DFit/28.jpeg' },
    { Pimg: '/product/5-ans-DFit/29.jpeg' },
    { Pimg: '/product/5-ans-DFit/30.jpeg' },
    { Pimg: '/product/5-ans-DFit/31.jpeg' },
    { Pimg: '/product/5-ans-DFit/32.jpeg' },
    { Pimg: '/product/5-ans-DFit/33.jpeg' },
    { Pimg: '/product/5-ans-DFit/34.jpeg' },
    { Pimg: '/product/5-ans-DFit/35.jpeg' },
    { Pimg: '/product/5-ans-DFit/36.jpeg' },
    { Pimg: '/product/5-ans-DFit/37.jpeg' },
    { Pimg: '/product/5-ans-DFit/38.jpeg' },
    { Pimg: '/product/5-ans-DFit/39.jpeg' },
    { Pimg: '/product/5-ans-DFit/40.jpeg' },
    { Pimg: '/product/5-ans-DFit/41.jpeg' },
    { Pimg: '/product/5-ans-DFit/42.jpeg' },
    { Pimg: '/product/5-ans-DFit/43.jpeg' },
    { Pimg: '/product/5-ans-DFit/44.jpeg' },
    { Pimg: '/product/5-ans-DFit/45.jpeg' },
    { Pimg: '/product/5-ans-DFit/46.jpeg' },
    { Pimg: '/product/5-ans-DFit/47.jpeg' },
    { Pimg: '/product/5-ans-DFit/48.jpeg' },
    { Pimg: '/product/5-ans-DFit/49.jpeg' },
    { Pimg: '/product/5-ans-DFit/50.jpeg' },
    { Pimg: '/product/5-ans-DFit/51.jpeg' },
    { Pimg: '/product/5-ans-DFit/52.jpeg' },
    { Pimg: '/product/5-ans-DFit/53.jpeg' },
    { Pimg: '/product/5-ans-DFit/54.jpeg' },
    { Pimg: '/product/5-ans-DFit/55.jpeg' },
    { Pimg: '/product/5-ans-DFit/56.jpeg' },
    { Pimg: '/product/5-ans-DFit/57.jpeg' },
    { Pimg: '/product/5-ans-DFit/58.jpeg' },
    { Pimg: '/product/5-ans-DFit/59.jpeg' },
    { Pimg: '/product/5-ans-DFit/60.jpeg' },
    { Pimg: '/product/5-ans-DFit/61.jpeg' },
    { Pimg: '/product/5-ans-DFit/62.jpeg' },
    { Pimg: '/product/5-ans-DFit/63.jpeg' },
    { Pimg: '/product/5-ans-DFit/64.jpeg' },
    { Pimg: '/product/5-ans-DFit/65.jpeg' },
    { Pimg: '/product/5-ans-DFit/66.jpeg' },
    { Pimg: '/product/5-ans-DFit/67.jpeg' },
    { Pimg: '/product/5-ans-DFit/68.jpeg' },
    { Pimg: '/product/5-ans-DFit/69.jpeg' },
    { Pimg: '/product/5-ans-DFit/70.jpeg' },
    { Pimg: '/product/5-ans-DFit/71.jpeg' },
    { Pimg: '/product/5-ans-DFit/72.jpeg' },
];

const modalStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '9999'
};

const imgStyles = {
    maxWidth: '50%',
    maxHeight: '50%',
    objectFit: 'contain',
};

const GallerySection = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentGallery, setCurrentGallery] = useState('one'); // Track which gallery is active
    const [shuffledImages, setShuffledImages] = useState([]);
    const [shuffledImagesTwo, setShuffledImagesTwo] = useState([]);

    useEffect(() => {
        const shuffled = shuffleArray(Portfolios);
        setShuffledImages(shuffled);
        const shuffledTwo = shuffleArray(PortfoliosTwo);
        setShuffledImagesTwo(shuffledTwo);
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                setModalOpen(false);
            }else if (event.key === 'ArrowLeft' && modalOpen) {
                prevImage();
            } else if (event.key === 'ArrowRight' && modalOpen) {
                nextImage();
            }
        };
        
        if (modalOpen) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [modalOpen, currentImageIndex]);

    const openModal = (index, gallery) => {
        setCurrentImageIndex(index);
        setCurrentGallery(gallery);
        setModalOpen(true);
    };

    // const closeModal = () => {
    //     setModalOpen(false);
    // };

    const nextImage = () => {
        if (currentGallery === 'one') {
            setCurrentImageIndex((current) => (current + 1) % shuffledImages.length);
        } else {
            setCurrentImageIndex((current) => (current + 1) % shuffledImagesTwo.length);
        }
    };

    const prevImage = () => {
        if (currentGallery === 'one') {
            setCurrentImageIndex((current) => (current - 1 + shuffledImages.length) % shuffledImages.length);
        } else {
            setCurrentImageIndex((current) => (current - 1 + shuffledImagesTwo.length) % shuffledImagesTwo.length);
        }
    };

    return (
        <section className='wpo-contact-pg-section section-padding'>
            <div className='container'>
                <div className='row'>
                    <h1 style={{textAlign:"center", fontWeight:"bold"}}>Quelques repas et boissons</h1><hr/>
                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                        <Masonry gutter="10px">
                            {shuffledImages.map((item, index) => (
                                <div key={index}>
                                    <img style={{ cursor: "pointer" }} src={item.Pimg} alt={`Portfolio Image ${index}`} onClick={() => openModal(index, 'one')} />
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>
                    <div style={{marginTop:"15%"}}></div>
                    <h1 style={{textAlign:"center", fontWeight:"bold"}}>Les 5 ans de D-FIT</h1><hr/>
                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                        <Masonry gutter="10px">
                            {shuffledImagesTwo.map((item, index) => (
                                <div key={index}>
                                    <img style={{ cursor: "pointer" }} src={item.Pimg} alt={`Portfolio Image ${index}`} onClick={() => openModal(index, 'two')} />
                                </div>
                            ))}
                        </Masonry>
                    </ResponsiveMasonry>

                    {modalOpen && (
                        <div style={modalStyles} onClick={() => setModalOpen(false)}>
                            <div onClick={prevImage}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50" fill="rgba(255,255,255,1)"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg></div>
                            <img src={currentGallery === 'one' ? shuffledImages[currentImageIndex].Pimg : shuffledImagesTwo[currentImageIndex].Pimg} style={imgStyles} alt={`Portfolio Image ${currentImageIndex}`} />
                            <div onClick={nextImage}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50" fill="rgba(255,255,255,1)"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg></div>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default GallerySection;