import React, { Fragment } from 'react';
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.svg'
import Astuce from '../../components/astucevideo/astuce';

const AstuceVideo = () => {
    return (
        <Fragment>
            <HeaderTop />
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <PageTitle pageTitle={'Astuces Vidéo'} pagesub={'Astuce'} />
            <Astuce />
            <Footer FooterShape={false} />
            <Scrollbar />
        </Fragment>
    );
}

export default AstuceVideo;
