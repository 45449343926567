import React, { Fragment } from 'react';
import HeaderTop from '../../components/HeaderTop/HeaderTop';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import Logo from '../../images/logo.svg'
import Menuakwa from '../../components/menuAkwa/menuakwa';

const MenuAkwa = () => {
    return (
        <Fragment>
            <HeaderTop />
            <Navbar hclass={'wpo-site-header'} Logo={Logo} />
            <PageTitle pageTitle={'Nos Menu du Restaurant Akwa'} pagesub={'Menu'} />
            <Menuakwa />
            <Footer FooterShape={false} />
            <Scrollbar />
        </Fragment>
    );
}

export default MenuAkwa;
