
/* Single image */
import img1 from '../images/service/serv-plan.jpg'
import img2 from '../images/service/serv-cour.jpg'
import img3 from '../images/service/serv-livrer.webp'
import img4 from '../images/service/serv-prog.jpg'
import img5 from '../images/service/D-FIT forfait diabétique.jpg'
import img6 from '../images/service/D-FIT forfait femme enceinte.jpg'
import img7 from '../images/service/D-FIT forfait premium.jpg'



import sion1 from '../images/service/icon-1.svg'
import sion2 from '../images/service/icon-2.svg'
import sion3 from '../images/service/icon-3.svg'
import sion4 from '../images/service/icon-4.svg'


import Simg1 from '../images/service-single/img1.jpg'
import Simg2 from '../images/service-single/img2.jpg'
import Simg3 from '../images/service-single/img3.jpg'
import Simg4 from '../images/service-single/img3.jpg'

const Services = [
    {
        id: 1,
        icon: sion1,
        simage: img1,
        imag: Simg1,
        title: 'Edition plans diététiques personnels',
        description: 'Des régimes sur mesure pour vos objectifs. Nous créons un plan nutritionnel personnalisé adapté à vos besoins et vos goûts',
        slug: 'Agriculture-Products'
    },
    {
        id: 3,
        icon: sion3,
        simage: img3,
        imag: Simg3,
        title: 'Livraison de repas diététique',
        description: 'Des repas équilibrés livrés où que vous soyez. Gagnez du temps et simplifiez votre quotidien tout en prenant soin de votre santé',
        slug: 'Organic-Fruits'
    },
    {
        id: 4,
        icon: sion4,
        simage: img4,
        imag: Simg4,
        title: 'Elaboration de programmes alimentaires',
        description: 'Besoin d\'un accompagnement personnalisé ? Nous élaborons des programmes alimentaires adaptés à vos objectifs et votre mode de vie',
        slug: 'Fresh-Vegetables'
    },
    {
        id: 2,
        icon: sion2,
        simage: img2,
        imag: Simg2,
        title: 'Cours de cuisine diététique',
        description: 'Apprenez à cuisiner sain et équilibré. Des cours pratiques pour maîtriser les bases de la cuisine diététique et réaliser des plats savoureux',
        slug: 'Dairy-Products'
    },
    {
        id: 5,
        icon: sion1,
        simage: img5,
        imag: Simg1,
        title: 'Forfait Diabétique',
        description: 'Maîtrisez votre diabète grâce à une alimentation équilibrée. Nos programmes alimentaires vous aident à réguler votre glycémie et à améliorer votre qualité de vie',
        slug: 'Agriculture-Products'
    },
    {
        id: 6,
        icon: sion1,
        simage: img6,
        imag: Simg1,
        title: 'Forfait Femme Enceinte',
        description: 'Un menu sur mesure pour une grossesse épanouie. Bénéficiez de conseils nutritionnels adaptés à vos besoins spécifiques',
        slug: 'Agriculture-Products'
    },
    {
        id: 7,
        icon: sion1,
        simage: img7,
        imag: Simg1,
        title: 'Forfait Premium',
        description: 'Un coaching nutritionnel sur-mesure pour atteindre vos objectifs. Bénéficiez d\'un accompagnement complet et d\'un suivi personnalisé',
        slug: 'Agriculture-Products'
    }
   


];
export default Services;