import React from 'react';

const Menuakwa = () => {
    return (
        <section className='wpo-blog-pg-section section-padding'>
            <style>
                {`
                .card-container {
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin: 20px;
                }

                .card {
                    padding: 10px;
                    text-align: center;
                    width: 400px;
                    margin-top: 5px;
                }

                .card img {
                    max-width: 100%;
                    border-radius: 5px;
                    cursor: pointer;
                }

                .card h2 {
                    font-size: 1.5em;
                    margin: 10px 0;
                }

                .card a {
                    text-decoration: none;
                    color: #333;
                }

                .card p {
                    font-size: 1em;
                    color: #000;
                }
                `}
            </style>
            <div className='container'>
                <div className='row'>
                    <h1 style={{textAlign:"center"}}> Bientôt Disponible !</h1>
                    <div className="card-container row">
                        <h1 style={{fontWeight:"bold", textAlign:"center"}}> </h1>
                        {/* <div className="card">
                            <img src="/product/visuels/Menu-2.jpg" alt="Image 1" /><br/>
                            <h2 style={{fontWeight:"bold"}}>30 Days Weight Loss Challenge</h2>
                            <p>Ceci est un texte descriptif pour la première carte.</p>
                        </div> */}
                    </div><br/>
                </div>
            </div>
        </section>
    );
}

export default Menuakwa;
